import { Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { finalSurveyUpload, verifySurveyStatus } from '../../features/propertydocument/propertydocumentSlice';
import { ConfirmSurveySchema } from '../../utils/Schemas';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function UploadFinalSurveyModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { document: doc, isLoading } = useSelector((state) => state.propertydocument);
  const { errors, touched, isSubmitting, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      surveyPdf: '',
    },
    validationSchema: ConfirmSurveySchema,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      for (let index = 0; index < values.surveyPdf.length; index += 1) {
        formData.append('surveyPdf', values.surveyPdf[index]);
      }

      dispatch(finalSurveyUpload({ formData, docId }));
    },
  });

  const handleImageUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFieldValue('surveyPdf', event.target.files);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload survey document
          </Typography>
          <Box
            onSubmit={handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <input id="pdf" type="file" style={{ display: '' }} accept=".pdf" onChange={handleImageUpload} />
                </div>
                {errors.surveyPdf && touched.surveyPdf && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.surveyPdf}</Typography>
                )}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              size="medium"
              type="submit"
            >
              {isLoading ? 'Please wait...' : 'Upload Final Survey Document'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
