import { Button, Card, Col, Descriptions, Image, Input, Modal, Row, Table, Timeline, Typography, Tag } from 'antd';
import Loader from '../components/loader/Loader';
import useSingleCofO from '../hooks/useSingleCofO';

const { Title, Text } = Typography;

const CofODetails = () => {
  const {
    singleCofO,
    loading,
    getStatusTag,
    isPriceModalOpen,
    setIsPriceModalOpen,
    price,
    handlePriceChange,
    handlePriceConfirm,
    isUploadModalOpen,
    setIsUploadModalOpen,
    uploadNotes,
    setUploadNotes,
    selectedFile,
    setSelectedFile,
    handleUpload,
    isLoading,
  } = useSingleCofO();

  const documentColumns = [
    {
      title: 'Document Type',
      dataIndex: 'documentType',
      key: 'documentType',
      render: (text) =>
        text
          ?.split('_')
          ?.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
          ?.join(' '),
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="link" href={record?.url} target="_blank">
          View Document
        </Button>
      ),
    },
  ];

  if (isLoading || !singleCofO) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  // Check if the C of O has been approved and uploaded
  const isCofOAvailable =
    singleCofO?.data?.CofO?.status === 'approved' &&
    singleCofO?.data?.CofO?.uploadOfCofOStatus === 'uploaded' &&
    singleCofO?.data?.CofO?.uploadOfCofO;

  // Prepare all documents including the approved C of O if available
  const allDocuments = [
    { ...singleCofO?.data?.CofO?.passportPhoto, key: 'passport' },
    { ...singleCofO?.data?.CofO?.deedOfSale, key: 'deed' },
    { ...singleCofO?.data?.CofO?.surveyPlan, key: 'survey' },
    ...(singleCofO?.data?.CofO?.additionalDocuments?.map((doc, idx) => ({
      ...doc,
      key: `additional-${idx}`,
    })) || []),
  ];

  // Add the approved C of O document if available
  if (isCofOAvailable) {
    allDocuments.push({
      ...singleCofO?.data?.CofO?.uploadOfCofO,
      key: 'approved-cofo',
    });
  }

  return (
    <div className="p-4 md:p-6">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <Title level={4} className="m-0 text-lg sm:text-xl break-words">
                Application Details: {singleCofO?.data?.CofO?.applicationId}
              </Title>

              <div className="flex gap-2">
                {!singleCofO?.data?.CofO?.paymentDue && (
                  <Button type="primary" className="bg-blue-600" onClick={() => setIsPriceModalOpen(true)}>
                    Confirm Price
                  </Button>
                )}

                {singleCofO?.data?.CofO?.paymentStatus === 'paid' &&
                  singleCofO?.data?.CofO?.uploadOfCofOStatus !== 'uploaded' && (
                    <Button type="primary" className="bg-green-600" onClick={() => setIsUploadModalOpen(true)}>
                      Upload C of O
                    </Button>
                  )}

                {isCofOAvailable && (
                  <Button
                    type="primary"
                    className="bg-green-600"
                    href={singleCofO?.data?.CofO?.uploadOfCofO?.url}
                    target="_blank"
                  >
                    View Approved C of O
                  </Button>
                )}
              </div>
            </div>

            <Descriptions bordered column={{ xs: 1, sm: 2 }} layout="vertical" className="w-full">
              <Descriptions.Item label="Applicant Name">{`${singleCofO?.data?.user?.Firstname} ${singleCofO?.data?.user?.Lastname}`}</Descriptions.Item>
              <Descriptions.Item label="Property Type">
                {singleCofO?.data?.CofO?.propertyType.charAt(0).toUpperCase() +
                  singleCofO?.data?.CofO?.propertyType.slice(1)}
              </Descriptions.Item>
              <Descriptions.Item label="Application Status">
                {getStatusTag(singleCofO?.data?.CofO?.status, 'status')}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Status">
                {getStatusTag(singleCofO?.data?.CofO?.paymentStatus, 'payment')}
              </Descriptions.Item>
              <Descriptions.Item label="Submission Date">
                {new Date(singleCofO?.data?.CofO?.submissionDate).toLocaleDateString()}
              </Descriptions.Item>
              <Descriptions.Item label="Last Updated">
                {new Date(singleCofO?.data?.CofO?.lastUpdated).toLocaleDateString()} {}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Due">
                {singleCofO?.data?.CofO?.paymentDue
                  ? `${new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'NGN', // Change to your preferred currency
                    }).format(singleCofO?.data?.CofO?.paymentDue)}`
                  : 'Not Set'}
              </Descriptions.Item>
              <Descriptions.Item label="C of O Status">
                {getStatusTag(singleCofO?.data?.CofO?.uploadOfCofOStatus || 'pending', 'upload')}
              </Descriptions.Item>
            </Descriptions>

            {isCofOAvailable && singleCofO?.data?.CofO?.approvalDetails && (
              <div className="mt-4">
                <Title level={5}>Approval Information</Title>
                <Descriptions bordered column={{ xs: 1, sm: 2 }} layout="vertical" className="w-full">
                  <Descriptions.Item label="Approval Date">
                    {new Date(singleCofO?.data?.CofO?.approvalDetails?.approvalDate).toLocaleDateString()}
                  </Descriptions.Item>
                  {singleCofO?.data?.CofO?.approvalDetails?.certificateNumber && (
                    <Descriptions.Item label="Certificate Number">
                      {singleCofO?.data?.CofO?.approvalDetails?.certificateNumber}
                    </Descriptions.Item>
                  )}
                  {singleCofO?.data?.CofO?.additionalNotes && (
                    <Descriptions.Item label="Additional Notes" span={2}>
                      {singleCofO?.data?.CofO?.additionalNotes}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </div>
            )}
          </Card>
        </Col>

        <Col xs={24}>
          <Card title="Required Documents">
            <div className="overflow-x-auto">
              <Table
                columns={documentColumns}
                dataSource={allDocuments}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </Card>
        </Col>

        <Col xs={24}>
          <Card title="Site Photos">
            <Image.PreviewGroup>
              <Row gutter={[16, 16]}>
                {singleCofO?.data?.CofO?.sitePhotos.map((photo, index) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={index}>
                    <Image
                      src={photo.url}
                      alt={`Site Photo ${index + 1}`}
                      className="w-full h-40 object-cover rounded"
                    />
                  </Col>
                ))}
              </Row>
            </Image.PreviewGroup>
          </Card>
        </Col>

        {singleCofO?.data?.CofO?.reviewNotes?.length > 0 && (
          <Col xs={24}>
            <Card title="Review Notes" className="overflow-x-auto">
              <Timeline className="px-4">
                {singleCofO?.data?.CofO?.reviewNotes.map((note, index) => (
                  <Timeline.Item key={index}>
                    <Text strong className="block">
                      {note.addedBy}
                    </Text>
                    <Text className="block whitespace-pre-wrap">{note.note}</Text>
                    <Text type="secondary" className="block mt-2">
                      {new Date(note.addedOn).toLocaleString()}
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Card>
          </Col>
        )}
      </Row>

      {/* Price Confirmation Modal */}
      <Modal
        title="Confirm C of O Price"
        open={isPriceModalOpen}
        onOk={handlePriceConfirm}
        onCancel={() => setIsPriceModalOpen(false)}
        confirmLoading={loading}
        okText={`${loading ? 'Please wait..' : 'Confirm Price'}`}
        okButtonProps={{
          className: 'bg-blue-500 hover:bg-blue-600',
        }}
      >
        <div className="space-y-4">
          <div>
            <p className="block text-sm font-medium text-gray-700 mb-1">Price (₦)</p>
            <Input placeholder="Enter price" value={price} onChange={handlePriceChange} className="w-full" />
          </div>
        </div>
      </Modal>

      {/* Upload Modal */}
      <Modal
        title="Upload C of O Document"
        open={isUploadModalOpen}
        onOk={handleUpload}
        onCancel={() => setIsUploadModalOpen(false)}
        confirmLoading={loading}
        okText={`${loading ? 'Please wait..' : 'Upload CofO'}`}
        okButtonProps={{
          className: 'bg-green-500 hover:bg-green-600',
        }}
      >
        <div className="space-y-4">
          <div>
            <p className="block text-sm font-medium text-gray-700 mb-1">Select Document</p>
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <p className="block text-sm font-medium text-gray-700 mb-1">Additional Notes</p>
            <Input.TextArea
              rows={4}
              placeholder="Enter any additional notes"
              value={uploadNotes}
              onChange={(e) => setUploadNotes(e.target.value)}
              className="w-full"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CofODetails;
