import { Tag, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { confirmCofOPrice, getSingleCofO, reset, uploadCofODocument } from '../features/c_of_o/c_of_o_Slice';
import { formatPrice } from '../utils/formatNumber';

const useSingleCofO = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // New state for price confirmation modal
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [price, setPrice] = useState('');

  // New state for upload modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadNotes, setUploadNotes] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const { singleCofO, isLoading, isSuccess, isError, message: msg } = useSelector((state) => state.cofo);

  const handlePriceChange = (e) => {
    const { value } = e.target;
    setPrice(formatPrice(value));
  };

  useEffect(() => {
    dispatch(getSingleCofO(id));
  }, [dispatch, id]);

  // Handle price confirmation
  const handlePriceConfirm = () => {
    if (!price) {
      message.error('Please enter price');
    }
    setLoading(true);
    dispatch(confirmCofOPrice({ cofoId: id, paymentDue: price.replace(/,/g, '') }));
  };

  // Handle file upload
  const handleUpload = () => {
    const formData = new FormData();
    formData.append('certificateOfOccupancy', selectedFile);
    formData.append('additionalNotes', uploadNotes);
    setLoading(true);
    dispatch(uploadCofODocument({ cofoId: id, formData }));
  };

  const getStatusTag = (status, type = 'status') => {
    const statusColors = {
      status: {
        pending: 'gold',
        under_review: 'processing',
        approved: 'success',
        rejected: 'error',
        assigned: 'blue',
        awaiting: 'purple',
      },
      payment: {
        pending: 'warning',
        paid: 'success',
      },
      upload: {
        pending: 'default',
        uploaded: 'success',
      },
    };

    const statusText = status
      ?.replace(/_/g, ' ')
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');

    return <Tag color={statusColors[type][status]}>{statusText}</Tag>;
  };

  useEffect(() => {
    if (isSuccess && msg === 'CofO Price Confirmed Succesfully') {
      message.success('CofO Price Confirmed Successfully');
      dispatch(getSingleCofO(id));
      setLoading(false);
      setIsPriceModalOpen(false);
      dispatch(reset());
    }

    if (isSuccess && msg === 'CofO Document Uploaded Succesfully') {
      message.success('CofO Document Uploaded Succesfully');
      dispatch(getSingleCofO(id));
      setLoading(false);
      setIsUploadModalOpen(false);
      dispatch(reset());
      setSelectedFile(null);
      setUploadNotes('');
    }

    if (isError) {
      message.error(msg);
      setLoading(false);
      dispatch(reset());
    }
  }, [isSuccess, isError, msg]);

  return {
    singleCofO,
    loading,
    getStatusTag,
    isPriceModalOpen,
    setIsPriceModalOpen,
    price,
    setPrice,
    handlePriceChange,
    handlePriceConfirm,
    isUploadModalOpen,
    setIsUploadModalOpen,
    uploadNotes,
    setUploadNotes,
    selectedFile,
    setSelectedFile,
    handleUpload,
    isLoading,
  };
};

export default useSingleCofO;
