import axios from 'axios';
import { API_URL } from '../api';

const getAllCofO = async (token, agentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/cofo/agent/${agentId}`, config);
  return response.data;
};

const getSingleCofO = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/cofo/agent/${data.agentId}/cofo/${data.cofoId}`, config);
  return response.data;
};

const confirmCofOPrice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${API_URL}/cofo/agent/${data.agentId}/cofo/${data.cofoId}/confirm_price`,
    data,
    config
  );
  return response.data;
};

const uploadCofODocument = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${API_URL}/cofo/agent/${data.agentId}/cofo/${data.cofoId}/upload_cofo`,
    data.formData,
    config
  );
  return response.data;
};

const cofoService = {
  confirmCofOPrice,
  getSingleCofO,
  getAllCofO,
  uploadCofODocument,
};
export default cofoService;
